<template>
    <div class="flex flex-col rounded-2xl">
        <img :src="flight.image" alt="" class="w-full aspect-[255/170] rounded-t-2xl">
        <div class="p-4 w-full flex flex-col bg-white rounded-b-2xl">
            <div class="border-b-2 border-[#E6E8EC] pb-4 mb-4">
                <p class="inter font-semibold mb-2 text-[20px]/[24px]">{{ flight.location }}</p>
                <p class="text-gray-text text-[14px]/[20px]" v-if="flight.weekdays">
                    {{ flight.weekdays }}
                </p>
            </div>
            <div class="font-semibold text-[16px]/[24px]" v-if="flight.price">
                {{ t('priceFrom', { price: flight.price, currency: flight.currency.symbol }) }}
            </div>
        </div>
    </div>
</template>

<script setup>
const { locale, t } = useI18n()
const dateFormatter = useFormattedDate()
const props = defineProps({
    flight: {
        type: Object,
        required: true
    }
})
</script>